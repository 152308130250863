/** @jsxImportSource @emotion/react  */
import { HashRouter, Routes, Route } from 'react-router-dom';

import { Global, css } from "@emotion/react";

import Admin from './Admin';
import Complete from './Complete';
import Qrcode from './Eka';

import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui';
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

Amplify.configure({
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id:  process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
  });
  
const App = () => {
    return (
        /*
        <HashRouter>
            <Global styles={global} />
            <div css={appCss}>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="form" element={<Form/>} />
                    <Route path="user" element={<User/>} />
                    <Route path="admin" element={<Admin/>} /> 
                    <Route path="complete" element={<Complete/>} />
                    <Route path="qrcode" element={<Qrcode/>} />
                    <Route path="qrcode_admin" element={<QrcodeAdmin/>} />
                    <Route path="about" element={<About/>} />
                </Routes>
            </div>
        </HashRouter>
    )

    */
       
        <Authenticator hideSignUp={true}>
        {({ signOut, user }) => (
            <main>
            <h1>のまっせQRコード印刷管理システム</h1>
            <button onClick={signOut}>Sign out</button>
            </main>
        )}
        
            <HashRouter>
                <Global styles={global} />
                <div css={appCss}>
                    <Routes>
                        <Route path="/" element={<Admin/>} />
                        <Route path="admin" element={<Admin/>} /> 
                        <Route path="complete" element={<Complete/>} />
                        <Route path="qrcode" element={<Qrcode/>} />
                    </Routes>
                </div>
            </HashRouter>
        </Authenticator>
        );
}

const global = css`
    h1 {
        font-size: calc(16px + 2vmin);
    }
    h2 {
        font-size: calc(14px + 2vmin);
    }
    footer {
        padding-top: 4%;
        padding-bottom: 4%;
        background-color: white;
        text-align: center;
        color: black;
    }
`;

const appCss = css`
    padding-bottom: 20px;
`;

export default App;
